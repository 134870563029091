/* General Styles */
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Londrina Solid';
  background-color: rgb(225, 215, 213);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 300%;
}

pre {
  font-size:30px;
  font-family: 'Londrina Solid';

} 

p {
  font-size:20px;
  font-family: 'Londrina Solid';

} 

.container {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
  position: relative;
  width: auto;
  max-width: 600px; 
}

.input-group {
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: center;
}

.input, .button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
}

.input {
  width: 200px;
  font-family: 'Londrina Solid';
  margin: 1px;
  border: 1px solid #ccc;
  text-align: center; 
}

.button {
  padding: 10px 20px;
  margin: 10px;
  background-color: #6495ED;
  font-family: 'Londrina Solid';
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.button_pay {
  padding: 10px 20px;
  margin: 10px;
  background-color: #6495ED;
  font-family: 'Londrina Solid';
  font-size: 25px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.button_pay:disabled {
  background-color: grey;
  font-family: 'Londrina Solid';
  color: #ccc;
  cursor: not-allowed;
}

.button_worldid {
  padding: 10px 20px;
  margin: 10px;
  background-color: #a9bee5;
  font-family: 'Londrina Solid';
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.button_spam {
  padding: 10px 20px;
  margin: 10px;
  background-color: rgb(252, 69, 69);
  font-family: 'Londrina Solid';
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button_valid {
  padding: 10px 20px;
  margin: 10px;
  background-color: #0aee56;
  font-family: 'Londrina Solid';
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button_currency {
  padding: 10px 20px;
  margin: 1px;
  background-color: lightgray;
  font-family: 'Londrina Solid';
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.button:hover {
  background-color: #4169E1;
}
.button_worldid:hover {
  background-color: #728fe3cc;
}

.wallet-address {
  color: grey;
  font-size: 12px;
  margin: 5px 0;
}

.headline {
  font-size: 42px;
  color: #333;
  margin-bottom: 10px;
}

/* TEMP */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
